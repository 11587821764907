<template>
  <div>
    <b-row>
      <b-col lg='6'>
        <div>
          <b-card no-body :img-src="require('@/assets/images/prospera.png')" />
        </div>
        <div class="ranking-style overflow-auto text-center">
          <div :key="indextr" v-for="(tr, indextr) in modules.ranking_top">
            <div v-if="tr.ranking_valor != null || tr.ranking_quantidade != null || tr.ranking_media != null || tr.ranking_indice != null">
              <b-alert
                variant="primary"
                show
              >
                <div class="alert-body">
                  <span class="text-center"><strong>{{tr.produto}}</strong></span>
                </div>
              </b-alert>
              <!-- <h5 class="text-center">{{tr.produto}}</h5> -->
              <div class="row" v-if="tr.ranking_valor != null || tr.ranking_quantidade != null || tr.ranking_media != null || tr.ranking_indice != null">
                <div class="col" v-if="tr.ranking_valor != null">
                  <b-card>
                    <b-img
                      fluid
                      width="90"
                      :src="require('@/assets/images/undraw/medal.svg')"
                    />
                    <b>
                      <h5>{{tr.ranking_valor.colaborador}}</h5>
                    </b>
                    <b>
                      <h5>Valor {{tr.ranking_valor.valor | dinheiro}}</h5>
                    </b>
                  </b-card>
                </div>
                <div class="col" v-if="tr.ranking_quantidade != null">
                  <b-card>
                    <b-img
                      fluid
                      width="90"
                      :src="require('@/assets/images/undraw/medal.svg')"
                    />
                    <b>
                      <h5>{{tr.ranking_quantidade.colaborador}}</h5>
                    </b>
                    <b>
                      <h5>Qtd. {{tr.ranking_quantidade.quantidade }}</h5>
                    </b>
                  </b-card>
                </div>
                <div class="col" v-if="tr.ranking_media != null">
                  <b-card>
                    <b-img
                      fluid
                      width="90"
                      :src="require('@/assets/images/undraw/medal.svg')"
                    />
                    <b>
                      <h5>{{tr.ranking_media.colaborador}}</h5>
                    </b>
                    <b>
                      <h5>Média {{tr.ranking_media.media | media }}</h5>
                    </b>
                  </b-card>
                </div>
                <div class="col" v-if="tr.ranking_indice != null">
                  <b-card>
                    <b-img
                      fluid
                      width="90"
                      :src="require('@/assets/images/undraw/medal.svg')"
                    />
                    <b>
                      <h5>{{tr.ranking_indice.colaborador}}</h5>
                    </b>
                    <b>
                      <h5>Indice {{tr.ranking_indice.indice}}</h5>
                    </b>
                  </b-card>
                </div>
              </div>
              <div v-else>
                <h5 class="text-danger">Nada informado</h5>
              </div>
            </div>
            <!-- <div v-else>
                <b-card class="text-center">
                    <h5>
                    Encontramos nenhum dado correspondente sobre o produto <b>{{tr.produto}}</b> em nosso sistema!
                    </h5>
                </b-card>
            </div> -->
          </div>
        </div>
      </b-col>
      <b-col lg="6">
        <b-card>
          <embed type="application/pdf" src="/prospera.pdf"   height="700px" width="100%">
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import { BCard, BCardText, BLink } from 'bootstrap-vue'
import pdf from 'vue-pdf'
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    pdf,
    mapActions,
    mapState,
  },
  directives: {
    Ripple
  },
  computed: {
    ...mapState("connectAPI", ["modules"])
  },
  methods: {
    fetchRanking(paginated = true, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("connectAPI/fetchRankingTop", {
          paginated: paginated,
          page: page,
          top: 1,
          generic: this.search,
          per_page: this.perPage
        })
        .then((response) => {
          this.totalRows = response.total
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  created() {
    this.fetchRanking();
  },
}
</script>
<style lang="scss">
.card-01-style {
  width: 100%;
  height: 350px;
}
.ranking-style {
  height: 45rem;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(75, 74, 74, 0.473);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(27, 27, 27);
  border-radius: 10px;
}
.text-format {
  width: 2.3ch;
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
</style>

